import React, { useState, useEffect } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppHeader from "../../components/AppHeader/AppHeader";
import ChallengeCard from "../../components/ChallengeCard";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { StarRating } from "../../../pages/ratingsScreen/RatingScreen";
import SucessMessage from "../../components/sucessToast/SucessToast";
import ToastMessage from "./../../../components/toast/ToastMessage";
import profImage from "../../../data/assests/profImage.jpg";
import "./index.css";
import BackButton from "../../../components/Button/BackButton";

const ChallengeRating = () => {
  const [ratingData, setratingData] = useState([]);
  const [challangeRating, setchallangeRating] = useState([]);
  const { id } = useParams();
  const [Rating, setRating] = useState("");
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [sucessToast, setSucessToast] = useState(false);
  const [showSuccessTost, setshowSuccessTost] = useState(false);
  const [messageData, setmessageData] = useState([]);
  const [userData, setUserData] = useState([]);

  // https://dmecart-38297.botics.co/business/challenge_rating/183/
  const [itemid, setItemid] = useState("");
  const [idd, setIdd] = useState("");
  const sendMessage = (id) => {
    setIdd("");

    const token = JSON.parse(sessionStorage.getItem("token"));
    const userid = parseInt(sessionStorage.getItem("userid"));

    if (!Rating) {
      setShowErrorToast(true);
      setIsError("Please enter message.");
    }

    const fetchData = async () => {
      try {
        // Replace the following with your logic for creating payment intent on the server
        const response = await fetch(
          "https://dmecart-38297.botics.co/business/challenge_rating/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
              business_rating: id,
              message: Rating,
              user: userid,
            }),
          }
        );

        const data = await response.json();
        if (data) {
          setshowSuccessTost(true);
          setSucessToast(true);
          setRating("");
          setIsSuccess("Your feedback has been successfully recorded.");
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      } catch (error) {
        setShowErrorToast(true);
        console.error("Error confirming payment:", error);
        // setMessage("An unexpected error occurred.");
        setIsError("An unexpected error occurred.");
      }
    };
    fetchData();
  };

  const fetchRatings = async () => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const userid = parseInt(sessionStorage.getItem("userid"));
    const response = await fetch(
      `https://dmecart-38297.botics.co/patients/ratings/${userid}/`,
      {
        method: "GET",
        headers: {
          // "Content-Type": "Application/json",
          Authorization: ` Token ${token}`,
        },
      }
    );
    const resData = await response.json();
    setUserData(resData.filter((item) => item.id == id));
  };
  //   console.log(userData, "geg");

  useEffect(() => {
    fetchRatings();
  }, []);

  const loadMore = async (itemid) => {
    setItemid(itemid);
    const token = JSON.parse(sessionStorage.getItem("token"));
    const userid = parseInt(sessionStorage.getItem("userid"));

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dmecart-38297.botics.co/business/challenge_rating/${userid}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "Application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const data = await response.json();
        if (data) {
          setchallangeRating(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const ontextChange = (id, value) => {
    //   setIdd(id)
    setRating(value);
  };
  console.log(ratingData, "ratingData");
  console.log(challangeRating, "challangeRating");
  React.useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const userid = parseInt(sessionStorage.getItem("userid"));

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dmecart-38297.botics.co/patients/ratings/${userid}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "Application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const data = await response.json();
        if (data) {
          console.log(data, "data");
          // setOrderData(data)
          // setratingData(data)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  React.useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const userid = parseInt(sessionStorage.getItem("userid"));

    const fetchChallengeData = async () => {
      try {
        const response = await fetch(
          `https://dmecart-38297.botics.co/business/challenge_rating_admin/${id}/`,
          {
            method: "GET",
            headers: {
              //  'Content-Type': 'Application/json',
              Authorization: `Token ${token}`,
            },
          }
        );

        const data = await response.json();
        if (data) {
          console.log(data, "data");
          // setOrderData(data)
          setratingData(data);
          // above code is showing error as it si giving and object, need to start work from her
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchChallengeData();
  }, []);

  //  const[challangeRating,setchallangeRating]=useState([])
  //  React.useEffect(() => {
  //      const token = JSON.parse(sessionStorage.getItem("token"));
  //      const userid =  parseInt(sessionStorage.getItem("userid"));

  //      const fetchData = async () => {
  //          try {

  //              const response = await fetch(`https://dmecart-38297.botics.co/business/challenge_rating/${userid}/`, {
  //                  method: 'GET',
  //                  headers: {
  //                    'Content-Type': 'Application/json',
  //                    'Authorization': `Token ${token}`
  //                  },
  //              });

  //              const data = await response.json();
  //              if (data) {
  //               setchallangeRating(data)

  //              }
  //          } catch (error) {
  //              console.error('Error fetching data:', error);
  //          }
  //      };

  //      fetchData();

  //    }, []);



  // const dateChange = (date) => {
  //   const date1 = new Date(date);
  //   // const hours = date.getHours();

  //   const currentTime = new Date();
  //   const timeDifference = currentTime - date1;
  //   const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  //   return hours;
  // };

  const dateChange = (date) => {
    const date1 = new Date(date);
    const currentTime = new Date();
    const timeDifference = currentTime - date1;
    
    // Calculate days
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
    // Calculate remaining hours after subtracting the days
    const remainingHours = timeDifference % (1000 * 60 * 60 * 24);
    const hours = Math.floor(remainingHours / (1000 * 60 * 60));

    return ` ${days} d, ${hours} h`;
};

  return (
    <div className="challenge-rating-page">
      <AppHeader />
      <div className="p-3 challenge-section">
        <div className="rounded bg-dark-subtle p-3 m-1">
          <div className="bg-light rounded p-3">
            <h1 className="my-1">Consumer Review</h1>
            {/* <ChallengeCard  ratingData={ratingData} /> */}

            <div className="mt-5">
              <h6 className="ms-2">{userData[0]?.patient_name}</h6>
              <div className="mt-3 d-flex">
                <span style={{paddingTop:"2px"}}>
                  {" "}
                  <StarRating rating={userData[0]?.stars} />
                </span>{" "}
                <span
                  className="ms-4"
                  style={{ color: "#B3B3B3", paddingBottom: "3px" }}
                >
                  <b>
                    {userData[0]?.created_at &&
                      dateChange(userData[0]?.created_at)}{" "}
                  </b>
                </span>
              </div>
              <p className="ms-2">{userData[0]?.message}</p>
            </div>
            {ratingData?.map((item) => (
              <div className="mt-3">
                <div className=" w-100">
                  <Row lg="10">
                    <Col lg="10">
                      <div className="business-logo-container">
                        {/* <img src={item.patient_avatar ? item.patient_avatar : profImage} className="profile-image" alt=' logo' /> */}
                        {/* <h4 className="m-1 ms-2">{item?.patient_name}</h4> */}
                      </div>
                      <div className="mt-3">
                        {/* <StarRating rating={Object.values(item)[0][0].stars} />
                        {console.log((Object.values(item)[0][0].stars),"itemmmm")} */}
                      </div>
                      <div className="w-100 mt-2 ms-2">
                        {Object.values(item)?.map(
                          (star) =>
                            star[0]?.created_by == "business" && (
                              <div>
                                <div className="d-flex">
                                  <h6 style={{ paddingTop: "3px" }}>
                                    Challenge by Business
                                  </h6>{" "}
                                  <span
                                    className="ms-3"
                                    style={{ color: "#B3B3B3" }}
                                  >
                                    <b>
                                    {Object.values(item)?.map((star) =>
                                      dateChange(star[0]?.created_at)
                                    )}{" "}
                                    </b>
                                  </span>
                                </div>
                                <p>
                                  {Object.values(item)?.map(
                                    (star) => star[0]?.message
                                  )}
                                </p>
                              </div>
                            )
                        )}
                        {Object.values(item)?.map(
                          (star) =>
                            star[0]?.created_by == "admin" && (
                              <div>
                                {/* <h6>Response from Admin</h6> */}
                                <p>
                                  {Object.values(item)?.map((star) =>
                                    star?.map((item) => (
                                      <div>
                                        {item.created_by == "admin" && (
                                          <div>
                                            <div className="d-flex">

                                            <h6 style={{ paddingTop: "3px" }}>Response from Admin</h6>
                                            <span
                                    className="ms-3"
                                    style={{ color: "#B3B3B3" }}
                                  >
                                    <b>
                                    {dateChange(item.created_at)
                                    }{" "}
                                    </b>
                                  </span>
                                            </div>

                                            <p>{item.message}</p>
                                          </div>
                                        )}
                                        {item.created_by == "business" && (
                                          <div className="mt-5">
                                            <div className="d-flex">
                                            <h6 style={{ paddingTop: "3px" }}>Challenge by Business</h6>
                                            <span
                                    className="ms-3"
                                    style={{ color: "#B3B3B3" }}
                                  >
                                    <b>
                                    {dateChange(item.created_at)
                                    }{" "}
                                    </b>
                                  </span>

                                            </div>
                                            <p>{item.message}</p>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  )}
                                </p>
                              </div>
                            )
                        )}

                        {/* <p onClick={() => loadMore(item.id)} className="loadMore cursor">Load more</p> */}
                        {/* {challangeRating?.map((msg)=>(
                            msg.business_rating === item.id && itemid === item.id &&
                            <p>{msg.message}</p>
                        ))} 
                        <div className="d-flex flex-column mt-3">
                            <label htmlfor='message-input' style={{ fontWeight: 'bold' }}>Add new Message</label>
                            <textarea className="bg-dark-subtle text_area p-1" rows={2} placeholder="Write your message here..." 
                            value={Rating}
                            onChange={(e) => ontextChange(item.id,e.target.value)}></textarea>
                           
                            <button type="button" className="btn btn-success mt-3 align-self-end m-1 p-5 pt-1 pb-1"
                             disabled={item.id != id}
                             onClick={() => sendMessage(item.id)}>Send</button>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                {showErrorToast ? (
                  <ToastMessage
                    show={showErrorToast}
                    message={isError}
                    onClose={() => setShowErrorToast(false)}
                  />
                ) : null}
                {showSuccessTost ? (
                  <SucessMessage
                    show={sucessToast}
                    message={isSuccess}
                    onClose={() => setSucessToast(false)}
                  />
                ) : (
                  ""
                )}

                {/* <hr /> */}
              </div>
            ))}

            <div className="d-flex flex-column mt-3 mb-4">
              <label htmlfor="message-input" style={{ fontWeight: "bold" }}>
                Add new Message
              </label>
              <textarea
                className="bg-dark-subtle text_area p-1 mt-2"
                rows={2}
                placeholder="Write your message here..."
                value={Rating}
                onChange={(e) => ontextChange(id, e.target.value)}
              ></textarea>
              <div className="mt-3 d-flex justify-content-between">
                <BackButton width={"140px"} height={"35px"} />
                <button
                  type="button"
                  className="btn btn-success px-5"
                  disabled={!Rating}
                  //  disabled={item.id != id}
                  onClick={() => sendMessage(id)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <AppFooter />
      </div>
    </div>
  );
};

export default ChallengeRating;
